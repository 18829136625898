/* ------------------------------- */
/* --- TypeScript Definitions ---- */
/* ------------------------------- */
import type { RouteMatchCallback, RouteMatchCallbackOptions, RouteHandler, HTTPMethod, RouteHandlerCallbackOptions, Route as IRoute, RouteHandlerObject } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { ExtendableEvent, FetchEvent, ExtendableMessageEvent, NotificationEvent, PushEvent, Response as IResponse } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { IFileInfo, IRouteInfo, IDefaultRouteInfo, IAppInfo, IIDBTransactionResult, IIDBTransactionErrorResult, TAppIdentifier, TFileIdentifier, TRouteIdentifier, TDefaultRouteIdentifier, IO365 } from 'o365.pwa.declaration.sw.O365.d.ts';

import type { ServiceWorkerState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';
import type { IServiceWorkerImportMap, IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';
import type { ServiceWorkerScriptState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare var Response: typeof IResponse;

/* ----------------------- */
/* --- Service Worker ---- */
/* ----------------------- */

/* <%CDN_BASE_URL%> */

importScripts('<%CDN_BASE_URL%>/workbox/v6.5.4/es5/workbox-sw.js');
importScripts('<%CDN_BASE_URL%>/dexie/v.3.2.3/main.es5.js');
importScripts('<%CDN_BASE_URL%>/omega365_fast_sort/3.4.0/fast-sort.worker.min.js');
importScripts('<%CDN_BASE_URL%>/omega365_mime/2.1.35/mime.worker.min.js');

Object.defineProperty(self.Dexie, "latestVersion", {
    get: function () {
        return self.Dexie['v3.2.3'];
    }
});

let workboxConfig = {
    'debug': true,
    modulePathPrefix: '<%CDN_BASE_URL%>/workbox/v6.5.4/es5/'
};

self.__WB_DISABLE_DEV_LOGS = true;

self.workbox.setConfig(workboxConfig);

const Workbox = self.workbox;
const WorkboxCore = Workbox.core;
const WorkboxRouting = Workbox.routing;

const { WorkboxError } = WorkboxCore;
const { RegExpRoute, Route } = WorkboxRouting;

class O365 implements IO365 {
    private onStartPromise?: Promise<void> = undefined;
    private _onStartCompleted = false;
    public get onStartCompleted() {
        return this._onStartCompleted;
    }

    private installEventPromise?: Promise<void> = undefined;
    private _installEventStarted = false;
    public get installEventStarted() {
        return this._installEventStarted;
    }
    private _installEventCompleted = false;
    public get installEventCompleted() {
        return this._installEventCompleted;
    }

    private activateEventPromise?: Promise<void> = undefined;
    private _activateEventStarted = false;
    public get activateEventStarted() {
        return this._activateEventStarted;
    }
    private _activateEventCompleted = false;
    public get activateEventCompleted() {
        return this._activateEventCompleted;
    }

    private appIdentifierQueue = new Array<TAppIdentifier>();
    private fileIdentifierQueue = new Array<TFileIdentifier>();

    private currentAppImportMap = <IServiceWorkerImportMap>{};

    private files = new Map<TFileIdentifier, IFileInfo>();
    private routes = new Map<TRouteIdentifier, IRouteInfo>();
    private defaultRoutes = new Map<TDefaultRouteIdentifier, IDefaultRouteInfo>();
    private apps = new Map<TAppIdentifier, IAppInfo>();

    public stateLog = new Array();

    private indexedDbBroadcastChannel = new BroadcastChannel('O365_PWA_INDEXED_DB_HANDLER_BROADCAST_CHANNEL');

    public get cdnUrl(): string {
        return '<%CDN_BASE_URL%>';
    }

    public get currentAppIdentifier(): TAppIdentifier | undefined {
        return this.appIdentifierQueue.at(-1);
    }

    public get currentFileIdentifier(): TFileIdentifier | undefined {
        return this.fileIdentifierQueue.at(-1);
    }

    public get currentParentFileIdentifier(): TFileIdentifier | undefined {
        if (this.fileIdentifierQueue.length < 2) {
            return undefined;
        }

        return this.fileIdentifierQueue.at(-2);
    }

    public get currentAppInfo(): IAppInfo | undefined {
        const currentAppIdentifier = this.currentAppIdentifier;

        if (currentAppIdentifier === undefined) {
            return undefined;
        }

        return this.apps.get(currentAppIdentifier);
    }

    public get currentFileInfo(): IFileInfo | undefined {
        const currentFileIdentifier = this.currentFileIdentifier;

        if (currentFileIdentifier === undefined) {
            return undefined;
        }

        return this.files.get(currentFileIdentifier);
    }

    public get currentParentFileInfo(): IFileInfo | undefined {
        const currentParentFileIdentifier = this.currentParentFileIdentifier;

        if (currentParentFileIdentifier === undefined) {
            return undefined;
        }

        return this.files.get(currentParentFileIdentifier);
    }

    private createErrorResponse(response: IResponse | null) {
        const style = `<style>
                        /* Style definitions */
                        * {
                            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                        }

                        .btn, .btn-link {
                            display: inline-block;
                            font-weight: 400;
                            color: #212529;
                            text-align: center;
                            vertical-align: middle;
                            user-select: none;
                            background-color: transparent;
                            border: 1px solid transparent;
                            padding: 0.375rem 0.75rem;
                            font-size: 1rem;
                            line-height: 1.5;
                            border-radius: 0.25rem;
                            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        }

                        .btn-primary {
                            color: #fff;
                            background-color: #4682B4;
                            border-color: #4682B4;
                        }

                        .btn-sm {
                            padding: 0.25rem 0.5rem;
                            font-size: 0.875rem;
                            line-height: 1.5;
                            border-radius: 0.2rem;
                        }

                        .d-flex {
                            display: flex !important;
                        }

                        .flex-column {
                            flex-direction: column !important;
                        }

                        .justify-content-center {
                            justify-content: center !important;
                        }

                        .align-items-center {
                            align-items: center !important;
                        }

                        .w100 {
                            width: 100vw !important;
                        }

                        .h100 {
                            height: 100vh !important;
                        }

                        .text-large {
                            font-size: 1.25rem;
                            font-weight: bold;
                        }

                        .text-normal {
                            font-size: 1.2rem;
                        }

                        .gap-2 {
                            gap: 0.5rem;
                        }

                        /* Adjustments for link appearance to match button */
                        .btn-link {
                            text-decoration: none;
                            display: inline-block; /* Align with buttons */
                            text-align: center;
                        }

                        /* isMobile */
                        @media only screen and (pointer: coarse) and (orientation: portrait) and (max-width: 600px), screen and (pointer: coarse) and (orientation: landscape) and (max-height: 600px) {
                            .text-large {
                                font-size: 2rem;
                                font-weight: bold;
                            }

                            .text-normal {
                                font-size: 1.5rem;
                                text-align: center;
                            }

                            .btn-sm {
                                font-size: 1.2rem;
                                padding: 0.4rem 0.8rem;
                            }
                        }
                    </style>`;

        const offlineLink = '<a href="/nt/offline/offline-home" class="btn btn-sm btn-primary btn-link">Go to offline home</a>';
        const myHomeLink = '<a href="/nt/myhome" class="btn btn-sm btn-primary btn-link">Go to home</a>';
        const refreshButton = '<button id="refreshButton" class="btn btn-sm btn-primary">Try again (refresh)</button>';

        const newResponseContent = `
                            ${style}
                            <div class="d-flex flex-column justify-content-center align-items-center w100 h100">
                                <div class="text-large">${response && response.status === 404 ? '404 - Not Found' : 'Request Failed'}</div> 
                                <div class="text-normal" style="padding-bottom: 0.4rem;">Something went wrong when loading the site.</div> 
                                <div class="d-flex gap-2" style="margin-top: 5px;">
                                    ${refreshButton}
                                    ${myHomeLink}
                                    ${this.apps.has('offline-home') ? offlineLink : ''}
                                </div> 
                                ${response && response.status && response.statusText ? `<div style='font-size: 1em; font-weight: 300; margin-top: 20px;'>${response.status} - ${response.statusText}</div>` : ''}
                            </div>
                            <script>
                                document.getElementById('refreshButton')?.addEventListener('click', function() {
                                    window.location.reload();
                                });

                                document.getElementById('offlineButton')?.addEventListener('click', function() {
                                    window.location.href = '/nt/offline/offline-home';
                                });
                            </script>
                            `;

        return new Response(newResponseContent, {
            headers: { "Content-Type": "text/html" },
            status: 200,
            statusText: "OK"
        });
    }

    public get logger() {
        return self['console'];
    }

    constructor() {
        self.addEventListener('install', this.handleServiceWorkerInstall.bind(this));
        self.addEventListener('activate', this.handleServiceWorkerActivate.bind(this));
        self.addEventListener('fetch', this.handleServiceWorkerFetch.bind(this));
        self.addEventListener('message', this.handleServiceWorkerMessage.bind(this));
        self.addEventListener('notificationclick', this.handleServiceWorkerNotificationClick.bind(this));
        self.addEventListener('notificationclose', this.handleServiceWorkerNotificationClose.bind(this));
        self.addEventListener('push', this.handleServiceWorkerPush.bind(this));

        this.handleServiceWorkerOnStart();
    }

    /* --------------------------------------- */
    /* ---- Service Worker Public Methods ---- */
    /* --------------------------------------- */
    // region

    public registerRoute(capture: RegExp | string | RouteMatchCallback | IRoute, handler?: RouteHandler, method?: HTTPMethod): void {
        const currentAppIdentifier = this.currentAppIdentifier;
        const currentFileIdentifier = this.currentFileIdentifier;

        if (currentAppIdentifier === undefined || currentFileIdentifier === undefined) {
            throw new Error('registerRoute cannot be called after the service worker have been installed');
        }

        const currentAppInfo = this.currentAppInfo;
        const currentFileInfo = this.currentFileInfo;

        if (currentAppInfo === undefined || currentFileInfo === undefined) {
            throw new Error('registerRoute cannot be called after the service worker have been installed');
        }

        const route = this.createRoute(capture, handler, method);

        const routeIdentifier = self.crypto.randomUUID();

        this.routes.set(routeIdentifier, {
            route,
            fileIdentifier: currentFileIdentifier,
            appIdentifiers: new Set([currentAppIdentifier]),
        });

        currentAppInfo.routeIdentifiers.push(routeIdentifier);
        currentFileInfo.routeIdentifiers.push(routeIdentifier);
    }

    public registerDefaultRoute(handler: RouteHandlerObject): void {
        const currentAppIdentifier = this.currentAppIdentifier;
        const currentFileIdentifier = this.currentFileIdentifier;

        if (currentAppIdentifier === undefined || currentFileIdentifier === undefined) {
            throw new Error('registerDefaultRoute cannot be called after the service worker have been installed');
        }

        const currentAppInfo = this.currentAppInfo;
        const currentFileInfo = this.currentFileInfo;

        if (currentAppInfo === undefined || currentFileInfo === undefined) {
            throw new Error('registerDefaultRoute cannot be called after the service worker have been installed');
        }

        if (currentFileInfo.defaultRouteIdentifier !== undefined) {
            throw new Error('registerDefaultRoute cannot be called multiple times in a script');
        }

        if (currentAppInfo.defaultRouteIdentifier !== undefined) {
            throw new Error('registerDefaultRoute cannot be called multiple times for an app');
        }

        const routeIdentifier = self.crypto.randomUUID();

        this.defaultRoutes.set(routeIdentifier, {
            handler,
            fileIdentifier: currentFileIdentifier,
            appIdentifiers: new Set([currentAppIdentifier]),
        });

        currentAppInfo.defaultRouteIdentifier = routeIdentifier;
        currentFileInfo.defaultRouteIdentifier = routeIdentifier;
    }

    public importScripts<T>(url: string | URL, importMapEntry?: IServiceWorkerImportMapEntry): T {
        const currentAppIdentifier = this.currentAppIdentifier;

        if (importMapEntry !== undefined) {
            const fileIdentifier = this.getImportMapEntryFileIdentifier(importMapEntry);

            const fileInfo = this.files.get(fileIdentifier);

            if (fileInfo === undefined) {
                throw new Error('Invalid ImportMapEntry provided');
            }

            return fileInfo.exportObject;
        }

        if (currentAppIdentifier === undefined) {
            throw new Error('importScripts cannot be called after the service worker have been installed');
        }

        let extraPath: string | undefined;

        if (typeof url === 'string' && url.includes('/')) {
            const parts = url.split('/');

            let cdnBaseUrl = parts.shift();

            if (cdnBaseUrl === undefined) {
                cdnBaseUrl = url;
            } else {
                cdnBaseUrl += '/';
            }

            url = cdnBaseUrl;

            extraPath = parts.join('/');
        }

        if (url instanceof URL) {
            url = url.toString();
        }

        importMapEntry = this.currentAppImportMap[url];

        if (importMapEntry === undefined) {
            throw Error(`Could not find importmap entry for URL: ${url}`);
        }

        const fileIdentifier = this.getImportMapEntryFileIdentifier(importMapEntry, extraPath);

        this.fileIdentifierQueue.push(fileIdentifier);

        if (this.files.has(fileIdentifier)) {
            this.registerExistingFileToApp(fileIdentifier, currentAppIdentifier);
        } else {
            let importUrl = importMapEntry.importUrl;

            if (extraPath !== undefined) {
                importUrl += extraPath;
            }

            this.files.set(fileIdentifier, {
                importMapEnty: importMapEntry,
                parentFileIdentifier: undefined,
                childFileIdentifiers: new Array(),
                appIdentifiers: new Set(),
                routeIdentifiers: new Array(),
                defaultRouteIdentifier: undefined,
                exportObject: undefined
            });

            this.files.get(fileIdentifier)?.appIdentifiers.add(currentAppIdentifier);
            this.currentFileInfo!.parentFileIdentifier = this.currentParentFileIdentifier;
            this.currentParentFileInfo?.childFileIdentifiers.push(fileIdentifier);
            this.currentAppInfo?.fileIdentifiers.push(fileIdentifier);

            try {
                self.importScripts(importUrl);
            } catch (reason) {
                this.logger.error(`Failed to import script: ${importUrl}`);
                throw reason;
            }

            this.files.get(fileIdentifier)!.exportObject ??= {};
        }

        this.fileIdentifierQueue.pop();

        return this.files.get(fileIdentifier)?.exportObject;
    }

    public exportScripts<T>(exportObject: T) {
        const currentFileIdentifier = this.currentFileIdentifier;

        if (currentFileIdentifier === undefined) {
            throw new Error('importScripts cannot be called after the service worker have been installed');
        }

        const currentFileInfo = this.currentFileInfo;

        if (currentFileInfo === undefined) {
            throw new Error('importScripts cannot be called after the service worker have been installed');
        }

        currentFileInfo.exportObject = exportObject;
    }

    public getImportMapEntry(fileIdentifier: TFileIdentifier): IServiceWorkerImportMapEntry | undefined {
        return this.files.get(fileIdentifier)?.importMapEnty;
    }

    public getImportMapEntryFromImportUrl(url: string | URL): IServiceWorkerImportMapEntry {
        let extraPath: string | undefined;

        if (typeof url === 'string' && url.includes('/')) {
            const parts = url.split('/');

            let cdnBaseUrl = parts.shift();

            if (cdnBaseUrl === undefined) {
                cdnBaseUrl = url;
            } else {
                cdnBaseUrl += '/';
            }

            url = cdnBaseUrl;

            extraPath = parts.join('/');
        }

        if (url instanceof URL) {
            url = url.toString();
        }

        const importMapEntry = this.currentAppImportMap[url];

        if (importMapEntry === undefined) {
            throw new Error('Failed to find importmap entry for ');
        }

        return importMapEntry;
    }

    // endregion

    /* --------------------------------------- */
    /* ---- Service Worker Event Handlers ---- */
    /* --------------------------------------- */
    // region

    private handleServiceWorkerInstall(this: O365, event: ExtendableEvent) {
        this._installEventStarted = true;

        this.installEventPromise = new Promise(async (resolve, reject) => {
            try {
                await this.onStartPromise ?? Promise.resolve();

                self.skipWaiting();

                const serviceWorkerStatesTransactionResult = await this.retrieveServiceWorkerStates();

                if (serviceWorkerStatesTransactionResult.success === false) {
                    // TODO: Implement better logic here
                    throw new Error('Failed to retrieve App List');
                }

                const requestResults = serviceWorkerStatesTransactionResult.requestResults;

                if (requestResults.length === 0) {
                    return resolve();
                }

                if (requestResults.length !== 1) {
                    // TODO: Implement better logic here
                    throw new Error('Failed to retrieve App List');
                }

                const serviceWorkerStatesRequestResult = requestResults[0];

                const serviceWorkerStates = serviceWorkerStatesRequestResult.result;

                for (const serviceWorkerState of serviceWorkerStates) {
                    const appId = serviceWorkerState.appId;

                    try {
                        const installed = serviceWorkerState.installed;
                        const readyForInstall = serviceWorkerState.readyForInstall;

                        const shouldImportApp = installed || readyForInstall;


                        if (shouldImportApp === false || this.apps.has(appId)) {
                            continue;
                        }

                        const importMap = serviceWorkerState.importMap;
                        const entrypoint = serviceWorkerState.entrypoint;

                        this.appIdentifierQueue.push(appId);

                        this.apps.set(appId, {
                            fileIdentifiers: new Array(),
                            routeIdentifiers: new Array(),
                            defaultRouteIdentifier: undefined,
                            installed: false
                        });

                        this.currentAppImportMap = importMap;

                        this.importScripts(entrypoint);

                        serviceWorkerState.installed = true;

                        this.apps.get(appId)!.installed = true;
                    } catch (reason) {
                        this.logger.error(`Failed to install service worker for app: ${serviceWorkerState.appId}`, reason);

                        serviceWorkerState.installed = false;
                    } finally {
                        await this.updateServiceWorkerState(serviceWorkerState);
                    }
                }

                resolve();
            } catch (reason) {
                this.logger.error('Failed to install service worker for all apps', reason);

                reject(reason);
            } finally {
                this._installEventCompleted = true;
            }
        });

        event.waitUntil(this.installEventPromise);
    }

    private handleServiceWorkerActivate(this: O365, event: ExtendableEvent) {
        this._activateEventStarted = true;

        this.activateEventPromise = new Promise(async (resolve, reject) => {
            try {
                await this.onStartPromise ?? Promise.resolve();
                await this.installEventPromise ?? Promise.resolve();

                await self.clients.claim();

                resolve();
            } catch (reason) {
                this.logger.error('Failed to activate service worker for all apps', reason);

                reject(reason);
            } finally {
                this._activateEventCompleted = true;
            }
        });

        event.waitUntil(this.activateEventPromise);
    }

    private handleServiceWorkerOnStart(this: O365): void {
        this.stateLog.push("Starting handleServiceWorkerOnStart");

        this.onStartPromise = new Promise<void>(async (resolve, reject) => {
            try {
                this.stateLog.push("Retrieving service worker states...");
                const serviceWorkerStatesTransactionResult = await this.retrieveServiceWorkerStates();

                if (serviceWorkerStatesTransactionResult.success === false) {
                    this.stateLog.push("Service worker states retrieval failed");
                    throw new Error('Failed to retrieve App List');
                }

                this.stateLog.push("Retrieving request results...");
                const requestResults = serviceWorkerStatesTransactionResult.requestResults;

                if (requestResults.length === 0) {
                    this.stateLog.push("No request results found, resolving...");
                    return resolve();
                }

                if (requestResults.length !== 1) {
                    this.stateLog.push("Unexpected number of request results");
                    throw new Error('Failed to retrieve App List');
                }

                this.stateLog.push("Retrieving service worker states result...");
                const serviceWorkerStatesRequestResult = requestResults[0];
                const serviceWorkerStates = serviceWorkerStatesRequestResult.result;

                this.stateLog.push("Processing service worker states...");
                for (const serviceWorkerState of serviceWorkerStates) {
                    try {
                        const appId = serviceWorkerState.appId;

                        if (!serviceWorkerState.installed || this.apps.has(appId)) {
                            this.stateLog.push(`Skipping appId: ${appId}`);
                            continue;
                        }

                        this.stateLog.push(`Adding appId to queue: ${appId}...`);
                        const importMap = serviceWorkerState.importMap;
                        const entrypoint = serviceWorkerState.entrypoint;

                        this.appIdentifierQueue.push(appId);

                        this.apps.set(appId, {
                            fileIdentifiers: [],
                            routeIdentifiers: [],
                            defaultRouteIdentifier: undefined,
                            installed: false,
                        });

                        this.stateLog.push(`Importing scripts for appId: ${appId}...`);
                        this.currentAppImportMap = importMap;
                        this.importScripts(entrypoint);

                        this.stateLog.push(`Updating service worker script states for appId: ${appId}...`);
                        const serviceWorkerScriptStates = this.apps.get(appId)!.fileIdentifiers.map((fileIdentifier) => {
                            return <ServiceWorkerScriptState>{
                                id: fileIdentifier,
                                appId,
                                importmapEntry: this.files.get(fileIdentifier)?.importMapEnty,
                            };
                        });

                        await this.updateServiceWorkerScriptStates(serviceWorkerScriptStates);

                        serviceWorkerState.installed = true;
                        this.apps.get(appId)!.installed = true;

                        this.stateLog.push(`Updating service worker state for appId: ${appId}...`);
                        await this.updateServiceWorkerState(serviceWorkerState);
                    } catch (reason) {
                        this.stateLog.push(`Error installing service worker for appId: ${serviceWorkerState.appId}`, reason);
                        serviceWorkerState.installed = false;
                    }
                }

                this.stateLog.push("Service worker onStart completed, resolving...");
                resolve();
            } catch (reason) {
                this.stateLog.push("Error during service worker onStart", reason);
                reject(reason);
            } finally {
                this._onStartCompleted = true;
            }
        });
    }


    private handleServiceWorkerFetch(this: O365, event: FetchEvent) {
        /// Return early to not intercept if ServiceWorker has started fully and there are no apps loaded
        if (this.onStartCompleted && (!this.installEventStarted || this.installEventCompleted) && (!this.activateEventStarted || this.activateEventCompleted) && this.apps.size === 0 && !event.request.headers.has('o365-workbox-strategy')) {
            return;
        }

        if (event.request.headers.has('X-O365-XHR') && !event.request.headers.has('o365-workbox-strategy')) {
            return;
        }

        const fetchHandlerPromise = new Promise<IResponse>(async (resolve, _reject) => {
            let response: IResponse | null = null;

            try {
                await this.onStartPromise ?? Promise.resolve();
                await this.installEventPromise ?? Promise.resolve();
                await this.activateEventPromise ?? Promise.resolve();

                const appId = await this.getAppIdFromFetchEvent(event);

                if (appId === undefined || !this.apps.has(appId) || !this.apps.get(appId)!.installed) {
                    try {
                        response = await fetch(event.request);
                    } catch (reason) {
                        this.logger.error(reason);

                        if (event.request.mode === 'navigate' && this.apps.has("offline-home")) {
                            response = this.createErrorResponse(null);
                        } else {
                            response = new Response(JSON.stringify({
                                error: "The request could not be completed due to a network error. Please check your internet connection and try again. If the problem persists, contact support for further assistance."
                            }), {
                                status: 500,
                                statusText: 'Internal ServiceWorker Error',
                            });
                        }
                    }

                    return resolve(response ?? new Response('No response generated', { status: 500 }));
                }

                const { request } = event;

                const routes = this.getAppRoutes(appId);

                const url = new URL(request.url, location.href);

                const sameOrigin = url.origin === location.origin;

                const { params, route } = this.findMatchingActivatedAppRoute({ url, sameOrigin, request, event }, routes);

                let handler: RouteHandlerObject | undefined = route && route.handler;

                if (!handler && this.apps.has(appId)) {
                    const defaultRouteIdentifier = this.apps.get(appId)?.defaultRouteIdentifier;

                    if (defaultRouteIdentifier) {
                        handler = this.defaultRoutes.get(defaultRouteIdentifier)?.handler;
                    }
                }

                if (!handler) {
                    return resolve(new Response(JSON.stringify({
                        error: 'Failed to find a valid handler for the request',
                    }), {
                        status: 500,
                        statusText: 'Internal Server Error'
                    }));
                }

                response = await handler.handle({ event, request, url, params });
            } catch (reason) {
                this.logger.error('Service worker failed to resolve fetch event', reason);

                if (event.request.mode === 'navigate') {
                    response = this.createErrorResponse(null);
                } else {
                    response = new Response(JSON.stringify(reason), {
                        status: 500,
                        statusText: 'Internal Server Error'
                    });
                }
            }

            resolve(response);
        });

        // TODO: Fix IntelliSense. Should work, just need types to be set correctly
        event.respondWith(fetchHandlerPromise);
    }

    private async handleServiceWorkerMessage(this: O365, event: ExtendableMessageEvent) {
        try {
            // TODO: Check that the message type is correct
            if (!event.data) {
                this.logger.error('No data received in message');
                return;
            }

            // Example processing logic; adapt this according to what you need to do with the data
            const message = event.data;

            if (message.type === "playwright") {
                const raceConditionObject = {};

                const isOnStartPromiseFufilled = (await Promise.race([this.onStartPromise, raceConditionObject])) === raceConditionObject ? false : true;
                const isOnInstallPromiseFufilled = (await Promise.race([this.installEventPromise, raceConditionObject])) === raceConditionObject ? false : true;
                const isOnActivatePromiseFufilled = (await Promise.race([this.activateEventPromise, raceConditionObject])) === raceConditionObject ? false : true;

                // TODO: Add more info from this

                const message = JSON.stringify({
                    isOnStartPromiseFufilled,
                    isOnInstallPromiseFufilled,
                    isOnActivatePromiseFufilled,
                });

                event.ports.forEach((port) => {
                    try {
                        port.postMessage(message);
                    } catch (reason) {
                        this.logger.error(reason);
                    }
                });
            }
            return;
        } catch (reason) {
            this.logger.error(reason);
        }
    }

    private handleServiceWorkerNotificationClick(this: O365, _event: NotificationEvent) { }

    private handleServiceWorkerNotificationClose(this: O365, _event: NotificationEvent) { }

    private handleServiceWorkerPush(this: O365, _event: PushEvent) { }

    // endregion

    /* ------------------------------------- */
    /* ---- Service Worker Util Methods ---- */
    /* ------------------------------------- */
    // region

    private async retrieveServiceWorkerStates(): Promise<IIDBTransactionResult<Array<ServiceWorkerState>>> {
        const db = await this.openDb();

        let result: IIDBTransactionResult<Array<ServiceWorkerState>> = {
            success: true,
            requestResults: new Array(),
        };

        if (db === null) {
            return result;
        }

        await new Promise<void>((resolve, reject) => {
            const transaction = db.transaction(['serviceWorkerStates'], 'readwrite');

            const objectStore = transaction.objectStore('serviceWorkerStates');

            const request = objectStore.getAll();

            request.onsuccess = () => {
                result.requestResults.push({
                    success: true,
                    result: request.result
                });
            }

            request.onerror = () => {
                result.success = false;

                (result as IIDBTransactionErrorResult).requestResults.push({
                    success: false,
                    error: request.error
                });
            }

            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);
        }).catch((reason) => {
            this.logger.error(reason);

            result = {
                ...result,
                success: false,
                error: reason
            }
        }).finally(() => {
            db.close();
        });

        return result;
    }

    private async updateServiceWorkerState(serviceWorkerState: ServiceWorkerState): Promise<IIDBTransactionResult<IDBValidKey>> {
        const db = await this.openDb();

        let result = <IIDBTransactionResult>{
            success: true,
            requestResults: new Array(1),
        };
        if (db === null) {
            return result;
        }

        await new Promise<void>((resolve, reject) => {
            const transaction = db.transaction(['serviceWorkerStates'], 'readwrite');

            const objectStore = transaction.objectStore('serviceWorkerStates');

            const request = objectStore.put(serviceWorkerState);

            request.onsuccess = () => {
                this.indexedDbBroadcastChannel.postMessage({
                    type: 'ServiceWorkerState',
                    appId: serviceWorkerState.appId
                });

                result.requestResults[0] = {
                    success: true,
                    result: request.result
                };
            }

            request.onerror = () => {
                result.success = false;

                result.requestResults[0] = {
                    success: false,
                    error: request.error
                };
            }

            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);
        }).catch((reason) => {
            this.logger.error(reason);

            result = {
                ...result,
                success: false,
                error: reason
            }
        }).finally(() => {
            db.close();
        });

        return result;
    }

    private async updateServiceWorkerScriptStates(serviceWorkerScriptStates: Array<ServiceWorkerScriptState>): Promise<IIDBTransactionResult<IDBValidKey>> {
        const db = await this.openDb();

        let result = <IIDBTransactionResult>{
            success: true,
            requestResults: new Array(serviceWorkerScriptStates.length),
        };

        if (db === null) {
            return result;
        }

        await new Promise<void>((resolve, reject) => {
            const transaction = db.transaction(['serviceWorkerScriptStates'], 'readwrite');

            const objectStore = transaction.objectStore('serviceWorkerScriptStates');

            for (const [index, serviceWorkerScriptState] of serviceWorkerScriptStates.entries()) {
                const request = objectStore.put(serviceWorkerScriptState);

                request.onsuccess = () => {
                    this.indexedDbBroadcastChannel.postMessage({
                        type: 'SerivceWorkerScriptState',
                        appId: serviceWorkerScriptState.appId,
                        serviceWorkerScriptId: serviceWorkerScriptState.id
                    });

                    result.requestResults[index] = {
                        success: true,
                        result: request.result
                    };
                }

                request.onerror = () => {
                    result.success = false;

                    result.requestResults[index] = {
                        success: false,
                        error: request.error
                    };
                }
            }

            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);
        }).catch((reason) => {
            this.logger.error(reason);

            result = {
                ...result,
                success: false,
                error: reason
            }
        }).finally(() => {
            db.close();
        });

        return result;
    }

    private openDb(): Promise<IDBDatabase | null> {
        return new Promise<IDBDatabase | null>(async (resolve, reject) => {
            const databases = await indexedDB.databases();
            let hasOpenedDB = false;
            for (const database of databases) {
                const databaseName = database.name;

                if (!databaseName || databaseName !== 'O365_PWA_CORE') {
                    continue;
                }
                hasOpenedDB = true;

                const openDatabaseRequest = indexedDB.open('O365_PWA_CORE');

                openDatabaseRequest.onsuccess = () => resolve(openDatabaseRequest.result);
                openDatabaseRequest.onblocked = () => reject(new Error('The database is blocked'));
                openDatabaseRequest.onerror = () => reject(openDatabaseRequest.error);
            }
            if (!hasOpenedDB) {
                resolve(null);
            }
        });
    }

    private async getAppIdFromFetchEvent(event: FetchEvent): Promise<TAppIdentifier | undefined> {
        if (event.request.url.startsWith('chrome-extension://')) {
            return undefined;
        }

        let appId = await this.getAppIdFromClientId(event.clientId);

        if (typeof appId === 'string' && this.apps.has(appId)) {
            return appId;
        }

        appId = this.getAppIdFromRequestUrl(event.request.url);

        if (typeof appId === 'string' && this.apps.has(appId)) {
            return appId;
        }

        appId = this.getAppIdFromReferrer(event.request.referrer);

        if (typeof appId === 'string' && this.apps.has(appId)) {
            return appId;
        }
        appId = this.getAppIdForViewPdfRequest(event.request.url);

        if (typeof appId === 'string' && this.apps.has(appId)) {
            return appId;
        }

        return undefined;
    }

    private async getAppIdFromClientId(clientId: string): Promise<TAppIdentifier | undefined> {
        try {
            const client = await self.clients.get(clientId);

            if (client) {
                const clientUrl = client.url;

                if (clientUrl.length === 0) {
                    return undefined;
                }

                // if (clientUrl.includes("/cdn/apryse/")) { // TODO: Fix. 
                //                                           // Temporary, needs to be fixed with a more permanent solution.
                //     return "pwa-documents";
                // }

                let appId = new URL(clientUrl).pathname.split('/').at(-1);

                if (appId) {
                    return appId;
                }
            }
        } catch (reason) {
            this.logger.error(reason);
        }

        return undefined;
    }

    private getAppIdFromReferrer(referrer: string): TAppIdentifier | undefined {
        try {
            if (referrer.length === 0) {
                return undefined;
            }

            let appId = new URL(referrer).pathname.split('/').at(-1);

            if (appId) {
                return appId;
            }
        } catch (reason) {
            this.logger.error(reason);
        }

        return undefined;
    }

    private getAppIdForViewPdfRequest(url: string): TAppIdentifier | undefined {
        try {
            if (url.length === 0) {
                return undefined;
            }

            let appId = new URL(url).pathname.split('/').at(4);

            if (appId) {
                return appId;
            }
        } catch (reason) {
            this.logger.error(reason);
        }

        return undefined;
    }

    private getAppIdFromRequestUrl(url: string): TAppIdentifier | undefined {
        try {
            if (url.length === 0) {
                return undefined;
            }

            let appId = new URL(url).pathname.split('/').at(-1);

            if (appId) {
                return appId;
            }
        } catch (reason) {
            this.logger.error(reason);
        }

        return undefined;
    }

    private getAppRoutes(appId: TAppIdentifier): Array<IRoute> {
        return this.apps.get(appId)?.routeIdentifiers
            .filter((routeIdentifier) => this.routes.has(routeIdentifier))
            .map((routeIdentifier) => this.routes.get(routeIdentifier)!.route) ?? [];
    }

    private findMatchingActivatedAppRoute({
        url,
        sameOrigin,
        request,
        event,
    }: RouteMatchCallbackOptions, routes: Array<IRoute>): {
        route?: IRoute;
        params?: RouteHandlerCallbackOptions['params'];
    } {
        for (const route of routes) {
            let params: Promise<any> | undefined;

            const matchResult = route.match({ url, sameOrigin, request, event });

            if (matchResult) {
                params = matchResult;

                if (Array.isArray(params) && params.length === 0) {
                    params = undefined;
                } else if (
                    matchResult.constructor === Object && Object.keys(matchResult).length === 0
                ) {
                    params = undefined;
                } else if (typeof matchResult === 'boolean') {
                    params = undefined;
                }

                return { route, params };
            }
        }

        return {};
    }

    private createRoute(capture: RegExp | string | RouteMatchCallback | IRoute, handler?: RouteHandler, method?: HTTPMethod): IRoute {

        let route: IRoute;

        if (typeof capture === 'string') {
            const captureUrl = new URL(capture, location.href);

            if (!(capture.startsWith('/') || capture.startsWith('http'))) {
                throw new WorkboxError('invalid-string', {
                    moduleName: 'workbox-routing',
                    funcName: 'registerRoute',
                    paramName: 'capture',
                });
            }

            // We want to check if Express-style wildcards are in the pathname only.
            // TODO: Remove this log message in v4.
            const valueToCheck = capture.startsWith('http')
                ? captureUrl.pathname
                : capture;

            // See https://github.com/pillarjs/path-to-regexp#parameters
            const wildcards = '[*:?+]';

            if (new RegExp(`${wildcards}`).exec(valueToCheck)) {
                throw new Error(
                    `The 'capture' parameter contains an Express-style wildcard ` +
                    `character (${wildcards}). Strings are now always interpreted as ` +
                    `exact matches; use a RegExp for partial or wildcard matches.`,
                );
            }

            const matchCallback: RouteMatchCallback = ({ url }) => {
                if (
                    url.pathname === captureUrl.pathname &&
                    url.origin !== captureUrl.origin
                ) {
                    throw new Error(
                        `${capture} only partially matches the cross-origin URL ` +
                        `${url.toString()}. This route will only handle cross-origin requests ` +
                        `if they match the entire URL.`,
                    );
                }

                return url.href === captureUrl.href;
            };

            // If `capture` is a string then `handler` and `method` must be present.
            route = new Route(matchCallback, handler!, method);
        } else if (capture instanceof RegExp) {
            // If `capture` is a `RegExp` then `handler` and `method` must be present.
            route = new RegExpRoute(capture, handler!, method);
        } else if (typeof capture === 'function') {
            // If `capture` is a function then `handler` and `method` must be present.
            route = new Route(capture, handler!, method);
        } else if (capture instanceof Route) {
            route = capture;
        } else {
            throw new WorkboxError('unsupported-route-type', {
                moduleName: 'workbox-routing',
                funcName: 'registerRoute',
                paramName: 'capture',
            });
        }

        return route;
    }

    private getImportMapEntryFileIdentifier(importMapEntry: IServiceWorkerImportMapEntry, extraPath?: string): string {
        switch (importMapEntry.type) {
            case 'site':
                return importMapEntry.fingerprint;
            case 'cdn':
                return `${importMapEntry.moduleName ?? importMapEntry.moduleId}/${importMapEntry.version}/${extraPath}`;
        }
    }

    private registerExistingFileToApp(fileIdentifier: TFileIdentifier, appIdentifier: TAppIdentifier) {
        const appInfo = this.apps.get(appIdentifier);

        if (appInfo === undefined) {
            return;
        }

        const fileInfo = this.files.get(fileIdentifier);

        if (fileInfo === undefined) {
            return;
        }

        if (fileInfo.appIdentifiers.has(appIdentifier)) {
            return;
        }

        fileInfo.appIdentifiers.add(appIdentifier);

        appInfo.fileIdentifiers.push(fileIdentifier);

        for (const routeIdentifier of fileInfo.routeIdentifiers) {
            const route = this.routes.get(routeIdentifier);

            if (route === undefined) {
                continue;
            }

            appInfo.routeIdentifiers.push(routeIdentifier);

            route.appIdentifiers.add(appIdentifier);
        }

        if (fileInfo.defaultRouteIdentifier !== undefined && appInfo.defaultRouteIdentifier !== undefined) {
            throw new Error('registerDefaultRoute cannot be called multiple times for an app');
        }

        if (fileInfo.defaultRouteIdentifier !== undefined) {
            appInfo.defaultRouteIdentifier = fileInfo.defaultRouteIdentifier;
        }

        for (const childFileIdentifier of fileInfo.childFileIdentifiers) {
            this.registerExistingFileToApp(childFileIdentifier, appIdentifier);
        }
    }

    // endregion
}

self.o365 = new O365();
